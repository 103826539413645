@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

* {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

*:focus {
    outline: none;
}

.abril {
    font-family: 'Abril Fatface', sans-serif;
}

.fredoka {
    font-family: 'Fredoka One', cursive;
}

#main {
    background: url('../images/homepage-background.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

#question, #review {
    height: 85vh;
    width: 100vw;
}

@media (min-width: 640px) { 
    #question, #review {
        height: 100vh;
    }
 }